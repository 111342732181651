import React, { useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import Checkout from '../../../components/Store/Checkout';
import loadFirestore from '../../../components/firebase-firestore';
import Loader from '../../../images/svg-loader';
let firestore = null;

const currencyMap = {
    gbp: '£',
    eur: '€',
};

async function loadProduct(productId) {
    if (!firestore) {
        firestore = await loadFirestore();
    }
    const snap = await firestore
        .collection('products')
        .doc(productId)
        .get();
    return snap.data();
}

const CheckoutDetails = () => {
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState();
    const [productId] = useQueryParam('productId');
    const [enableCouponCode] = useQueryParam('enable-coupon-code');

    const showCouponCode = enableCouponCode === 'true';

    useEffect(() => {
        loadProduct(productId).then(resp => {
            setProduct(resp);
            setLoading(false);
        });
    }, [productId]);

    let price = 0;
    if (product) {
        price = product.upfront.amount;
        if (product.upfront.split) {
            price = price / product.upfront.split;
        }
    }
    const checkoutData = {
        planCode: productId,
        name: product && product.name,
        currentPrice: price * 100,
        price: price * 100,
        setupFees: 0,
        currency: product ? currencyMap[product.currency ?? 'eur'] : 'eur',
        currencyCode: product?.currency,
    };

    return (
        <Layout noFooter>
            <SEO
                title="Checkout - Gym Plan Subscription | J Clarke Fitness"
                description="My brand new Transformation Program is finally here and it is bigger and better than ever before!"
            />
            {loading ? (
                <div className="processing-loader">
                    {' '}
                    <Loader />
                </div>
            ) : (
                <Checkout
                    program="transformation-program"
                    checkoutData={checkoutData}
                    showCouponCodeInput={showCouponCode}
                />
            )}
            <style jsx>{`
                .processing-loader {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0.7);
                    top: 0;
                    left: 0;

                    & > :global(svg) {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin: -25px 0 0 -25px;
                        stroke: red;
                    }
                }
            `}</style>
        </Layout>
    );
};

export default CheckoutDetails;
